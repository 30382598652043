import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Property extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="properties-area pd-top-118 pd-bottom-90">
                  <div className="container">
                    <div className="section-title text-center">
                      <h6>Descubre Nuestros Lotes Exclusivos</h6>
                      <h2>Lotes Residenciales Ideales para Tu Nuevo Hogar</h2>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Lotes de "TripticoRNvo2023 (2)" */}
                        <div className="single-product-wrap style-bottom">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/project/1.png"} alt="Lote Residencial TripticoRNvo2023" />
                          </div>
                          <div className="product-details-inner">
                            <h4><Link to="/property-lotes">Lotes en Rancho Nuevo Residencial</Link></h4>
                            <ul className="meta-inner">
                              <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="Ubicación" />Tulancingo, Hidalgo</li>
                              <li>En Venta</li>
                            </ul>
                            <p>Superficie de 140 m² (7m x 20m), con amenidades como asador, área verde y juegos infantiles. Servicios ocultos y acceso automatizado.</p>
                          </div>
                          <div className="product-meta-bottom">
                            <span className="price">Desde $524,990 MXN</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Lotes de "Brochure Tilo (2)" */}
                        <div className="single-product-wrap style-bottom">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/project/2.png"} alt="Lote Residencial Brochure Tilo" />
                          </div>
                          <div className="product-details-inner">
                            <h4><Link to="/property-lotes">Lotes en Privada Tilo</Link></h4>
                            <ul className="meta-inner">
                              <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="Ubicación" />Cercanía a Plaza Patio Tulancingo</li>
                              <li>En Venta</li>
                            </ul>
                            <p>Terrenos de 105 m² (7m x 15m), con seguridad, áreas verdes, juegos infantiles, y servicios ocultos. Opciones de financiamiento disponibles.</p>
                          </div>
                          <div className="product-meta-bottom">
                            <span className="price">Consulta para más detalles</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

        }
}

export default Property
