import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PropertyGrid extends Component {

    componentDidMount() {
        const $ = window.$;
        $('body').addClass('body-bg');
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div className="blog-page-area pt-5 go-top">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* Información de las casas, manteniendo la estructura del código original */}
                        {/* Ejemplo para una casa */}
                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/16.png"} alt="Casa" />
                                </div>
                                <div className="product-details-inner">
                                    <h4><Link to="/property-details-arboledas">Casa Arboledas en Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li>2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p>Diseño contemporáneo con terraza y habitación en planta baja.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 3</span>
                                    <span className="border-none">Num baños: <br/> 2</span>
                                    <span>Tamaño: <br/>114 m²</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/15.png"} alt="Casa" />
                                </div>
                                <div className="product-details-inner">
                                    <h4><Link to="/property-details">Casa Azucena en Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li>2 plantas, Estacionamiento techado, Zaguan de acero</li>
                                    </ul>
                                    <p>Dos cómodas plantas que maximiza cada rincón de tu hogar</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 2</span>
                                    <span className="border-none">Num baños: <br/> 1.5</span>
                                    <span>Tamaño: <br/>95.9 m²</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/17.png"} alt="Casa" />
                                </div>
                                <div className="product-details-inner">
                                    <h4><Link to="/property-details-olivo">Casa Olivo en <br/> Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li>2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p>Con habitación principal amplia, sala y comedor espaciosos.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 4</span>
                                    <span className="border-none">Num baños: <br/> 3.5</span>
                                    <span>Tamaño: <br/>152 m²</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/18.png"} alt="Casa" />
                                </div>
                                <div className="product-details-inner">
                                    <h4><Link to="/property-lotes">Privada Rosal</Link></h4>
                                    <ul className="meta-inner">
                                        <li>Amenidades completas, Servicios ocultos, Seguridad 24/7</li>
                                    </ul>
                                    <p>Ubicada en Calle Rosal, ofrece un estilo de vida ideal con espacios verdes y juegos infantiles.</p>
                                    <span className="price">Terrenos desde $524,990</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/18.png"} alt="Casa" />
                                </div>
                                <div className="product-details-inner">
                                    <h4><Link to="/property-lotes">Privada Tilo</Link></h4>
                                    <ul className="meta-inner">
                                        <li>Ambiente familiar, Áreas de recreación, Acceso controlado</li>
                                    </ul>
                                    <p>Una opción perfecta para la vida moderna, con acceso a servicios y amenidades de calidad.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                            </div>
                        </div>
                        {/* Repetir para cada casa disponible en los PDFs */}
                        {/* ... */}
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyGrid;
