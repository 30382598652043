import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Service extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="service-area">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-10">
                        <div className="single-service-wrap">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/service/1.png"} alt="icon" />
                          </div>
                          <div className="single-service-details">
                            <h4><a href="property-details.html">Valuación de Propiedades</a></h4>
                            <p>Análisis detallado y preciso para establecer el valor real de tu inmueble, utilizando tecnología de punta y datos del mercado.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-10">
                        <div className="single-service-wrap">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/service/2.png"} alt="icon" />
                          </div>
                          <div className="single-service-details">
                            <h4><a href="property-details.html">Consultoría y Optimización</a></h4>
                            <p>Asesoramiento experto para mejorar tu propiedad y alinearla con las demandas del mercado, aumentando su valor y atractivo.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-10">
                        <div className="single-service-wrap mb-0">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/service/3.png"} alt="icon" />
                          </div>
                          <div className="single-service-details">
                            <h4><a href="property-details.html">Marketing Inmobiliario Digital</a></h4>
                            <p>Estrategias digitales personalizadas para maximizar la visibilidad y atractivo de tu propiedad, conectando con el público ideal.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    }
}

export default Service
