import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PropertyDetailsOlivo extends Component {

    componentDidMount() {
        const $ = window.$;
        $('body').addClass('body-bg');
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div className="property-page-area pd-top-120 pd-bottom-90 ">
                <div className="container">
                    <div className="property-details-top pd-bottom-70">
                        <div className="property-details-top-inner">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h3>Casa Olivo en Rancho Nuevo Residencial</h3>
                                    <p><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" /> Excelente ubicación en el residencial </p>
                                    <ul>
                                        <li>4 habitaciones</li>
                                        <li>3 baños y medio</li>
                                        <li>152 m² de construcción</li>
                                    </ul>
                                </div>
                                <div className="col-lg-5 text-lg-right">
                                    <h4>Consultar precio</h4>
                                    <div className="btn-wrap">
                                        <a className="btn btn-base btn-sm" href="contact">Contactar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-thumbnail-wrapper">
                            {/* Aquí van las imágenes de la plantilla */}
                            <div className="single-thumbnail-slider">
                                <div className="slider-item">
                                    <img src={publicUrl+"assets/img/project-single/3.png"} alt="img" />
                                </div>
                                {/* Repetir para más imágenes */}
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="single-property-details-inner">
                                <h4>Descripción</h4>
                                <p>Casa Olivo ofrece un amplio espacio de 152 m² distribuidos en dos niveles, perfecto para familias grandes. Con cuatro habitaciones y tres baños y medio, ofrece comodidad y privacidad.</p>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-property-grid">
                                            <h4>Especificaciones Interiores</h4>
                                            <ul>
                                                <li>Loseta de cerámica</li>
                                                <li>Duela Vinílica</li>
                                                <li>Tarja</li>
                                                <li>Tinaco</li>
                                                <li>Calentador de agua</li>
                                                <li>Impermeabilizante</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-property-grid">
                                            <h4>Especificaciones Exteriores</h4>
                                            <ul>
                                                <li>2 plantas</li>
                                                <li>Zaguan de Acero</li>
                                                <li>Estacionamiento Techado</li>
                                                <li>2 habitaciones</li>
                                                <li>1 baño y medio</li>
                                                <li>Amenidades</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyDetailsOlivo;
