import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Client extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div>
                  <div className="client-area client-area-pd bg-overlay pd-top-118" style={{background: 'url('+publicUrl+'assets/img/other/1.png)'}}>
                    <div className="container">
                      <div className="row justify-content-center text-lg-left text-center">
                        <div className="col-lg-9">
                          <div className="section-title style-white mb-0">
                            <h6>Nuestros Clientes</h6>
                            <h2>Encuentra tu Espacio Ideal con Nosotros</h2>
                            <p>En Alpine Habitat, conectamos a nuestros clientes con propiedades que no solo cumplen sino que superan sus expectativas. Gracias a nuestro análisis de datos y conocimiento del mercado, cada inversión se convierte en una historia de éxito.</p>
                          </div>
                        </div>
                        <div className="col-lg-3 align-self-end text-lg-right mt-4 mt-lg-0 go-top">
                          <Link className="btn btn-base" to="/contact">Contáctanos</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="client-slider bg-main style-two owl-carousel">
                      {/* Logos de Clientes */}
                      {/* Asegúrate de tener los derechos para mostrar los logos de cualquier cliente */}
                      <div className="item">
                        <div className="thumb">
                          <img src={publicUrl+"assets/img/client/client1.png"} alt="Cliente 1" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="thumb">
                          <img src={publicUrl+"assets/img/client/client2.png"} alt="Cliente 2" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="thumb">
                          <img src={publicUrl+"assets/img/client/client3.png"} alt="Cliente 3" />
                        </div>
                      </div>
                      {/* Continúa con más logos si es necesario */}
                    </div>
                  </div>
                </div>

        }
}

export default Client
