import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="testimonial-area pd-top-118 pd-bottom-120 bg-overlay" style={{background: 'url('+publicUrl+'assets/img/bg/2.png)'}}>
			  <div className="bg-overlay-wrap">
			    <div className="section-title style-white text-center">
			      <h6>Nuestros Clientes Dicen</h6>
			      <h2>Experiencias con Alpine Habitat</h2>
			      <p>Conoce las historias de éxito y satisfacción de quienes han confiado en nosotros.</p>
			    </div>
			    <div className="container">
			      <div className="row justify-content-center">
			        <div className="col-lg-10">
			          <div className="testimonial-slider-1 owl-carousel">
			            <div className="item">
			              <div className="single-testimonial-inner style-two text-center">
			                <div className="thumb main-thumb">
			                  <img src={publicUrl+"assets/img/testimonial/1.png"} alt="img" />
			                </div>
			                <div className="details">
			                  <h6 className="name">Laura Espinosa</h6>
			                  <p>La asesoría y el soporte tecnológico de Alpine Habitat fueron esenciales para encontrar el hogar ideal para mi familia. Su profesionalismo y atención al detalle nos impresionaron. </p>
			                  <div className="rating-inner">
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="far fa-star" aria-hidden="true" />
			                  </div>
			                </div>
			              </div>
			            </div>
			            <div className="item">
			              <div className="single-testimonial-inner style-two text-center">
			                <div className="thumb main-thumb">
			                  <img src={publicUrl+"assets/img/testimonial/4.png"} alt="img" />
			                </div>
			                <div className="details">
			                  <h6 className="name">Jorge Mendoza</h6>
			                  <p>“Invertir en bienes raíces siempre me pareció complicado, pero Alpine Habitat lo hizo sencillo y seguro. Su análisis de mercado y asesoría personalizada me dieron la confianza que necesitaba.”</p>
			                  <div className="rating-inner">
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="fas fa-star" />
			                    <i className="far fa-star" aria-hidden="true" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial