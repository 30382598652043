import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Banner extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="banner-area banner-area-1 banner-area-bg" style={{background: 'url('+publicUrl+'assets/img/banner/1.png)'}}>
                  <div className="container">
                    <div className="banner-area-inner">
                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <div className="banner-inner text-center">
                            <p>Descubre un mundo donde la precisión inmobiliaria y la innovación se unen.</p>
                            <div className="line" />
                            <h2>Alpine Habitat Redefiniendo el Hogar Perfecto a Través de la Tecnología</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

    }
}

export default Banner
