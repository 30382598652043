import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class About extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="about-area bg-gray pd-top-120 pd-bottom-120">
                  <div className="container">
                    <div className="single-about-inner about-line-right bg-white">
                      <div className="row no-gutter">
                        <div className="col-lg-4 order-lg-12">
                          <div className="thumb" style={{background: 'url('+publicUrl+'assets/img/other/2.png)'}} />
                        </div>
                        <div className="col-lg-8 order-lg-1">
                          <div className="details">
                            <div className="section-title mb-4">
                              <h6>Quiénes Somos</h6>
                              <h2>DatAlpine: Un Producto de Teseo Data Lab</h2>
                              <p>DatAlpine es un producto innovador de TESEO DATA LAB, un equipo global de científicos de datos con una amplia trayectoria en más de 18 proyectos inmobiliarios en países como México, Japón, Estados Unidos, Canadá, Suiza y Alemania. Nuestro compromiso es transformar los datos en estrategias efectivas para el mercado inmobiliario.</p>
                              <p>Como expertos en desarrollo de software, analítica de datos e inteligencia artificial, ofrecemos soluciones precisas y eficaces, generando resultados concretos y valiosos para nuestros clientes en el sector inmobiliario.</p>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <h5>Certificaciones:</h5>
                                <ul className="single-list-inner">
                                  <li>CIRA Canadá Certificate</li>
                                  <li>Certificado de Negocios de Alberta</li>
                                </ul>
                                <h5>Presencia Global:</h5>
                                <p>México, Colombia, Canadá</p>
                              </div>
                            </div>  
                            <Link to="https://teseodata.com/" className="btn btn-base">DESCUBRE MÁS</Link>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>

        }
}

export default About
